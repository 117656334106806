import { library } from "@fortawesome/fontawesome-svg-core";
import { isIOS } from "react-device-detect";
import {
  faCreditCard,
  faDice,
  faCommentAlt,
  faMoneyBillWave,
  faNewspaper,
  faWrench,
  faExchangeAlt,
  faStore,
  faFireAlt,
  faCoins,
  faMoneyBill,
  faPalette,
  faCashRegister,
  faCode,
  faChartBar

} from "@fortawesome/free-solid-svg-icons";
library.add(
  faCreditCard,
  faDice,
  faCommentAlt,
  faMoneyBillWave,
  faNewspaper,
  faWrench,
  faExchangeAlt,
  faStore,
  faFireAlt,
  faCoins,
  faMoneyBill,
  faPalette,
  faCashRegister,
  faCode,
  faChartBar
);

const categories = [
  {
    name: "Decentralized finance",
    displayIos: false,
    icon: "coins",
    color: "#5cd879",
    dapps: [
      {
        name: "Yedi Network",
        url: "https://yedi.net/",
        description: "The worlds #1 refelction tracker",
        icon: "./images/dapps/yedi.png",
      },
      {
        name: "Bubble Maps",
        url: "https://app.bubblemaps.io/",
        description: "Innnovative Visuals for blokchain data",
        icon: "./images/dapps/bubblemaps.png",
      },
    ],
  },
  {
    name: "Decentralized exchanges",
    displayIos: false,
    icon: "cash-register",
    color: "#bbb9f3",
    dapps: [
      {
        url: "https://donaswap.com/",
        name: "Donaswap Exchange",
        shortName: "Donaswap",
        description: "The hottest cross-chain AMM!",
        icon: "./images/dapps/donaswap.png",
      },
      {
        name: "Uniswap",
        url: "https://app.uniswap.org/swap",
        description: "Swap and trade tokens on the Ethereum network",
        icon: "./images/dapps/uniswap.exchange.png",
      },
      {
        name: "ShibaSwap",
        url: "https://shibaswap.com/",
        description: "The dex of the Shibarmy on the Ethereum network",
        icon: "./images/dapps/shibaswap.png",
      },
      {
        name: "PancakeSwap",
        url: "https://pancakeswap.finance/swap",
        description: "PancakeSwap is a Decentralized Exchange (DEX) on the Binance Smart Chain",
        icon: "./images/dapps/pancakeswap.png",
      },
      {
        name: "CronaSwap",
        url: "https://app.cronaswap.org/swap",
        description: "first decentralized exchange platform on the Cronos Chain",
        icon: "./images/dapps/cronaswap.png",
      },
      {
        name: "QuickSwap",
        url: "https://quickswap.exchange/#/",
        description: "QuickSwap is a fork of Uniswap that runs on the Polygon network",
        icon: "./images/dapps/quickswap.png",
      },
      {
        name: "SpookySwap",
        url: "https://spooky.fi/#/swap",
        description: "SpookySwap is an automated market-making (AMM) for the Fantom Opera network",
        icon: "./images/dapps/spookyswap.svg",
      },
    ],
  },
  {
    name: "Art & collectibles",
    displayIos: false,
    icon: "palette",
    color: "#F29D62",
    dapps: [
      {
        name: "Punk Knights",
        url: "https://punkknights.com/",
        description:
          "Punk Knights is a collection of 1,125 NFKs hidding a secret within.",
        icon: "./images/dapps/punkknights.png",
      },
      // {
      //   name: "FireSea",
      //   url: "https://thefiresea.com/",
      //   description: "Buy, sell, and discover rare digital items",
      //   icon: "./images/dapps/firesea.png",
      // },
      {
        name: "OpenSea",
        url: "https://opensea.io/",
        description: "Buy, sell, and discover rare digital items",
        icon: "./images/dapps/opensea.io.png",
      },
      // {
      //   name: "Ryoshi Labs",
      //   url: "https://ryoshis.vision",
      //   description: "Ryoshi's Vision NFT launchpad for creators",
      //   icon: "./images/dapps/ryoshisvision.png",
      // },
      {
        name: "Rarible",
        url: "https://rarible.com/",
        description: "Create and sell digital collectibles",
        icon: "./images/dapps/rarible.com.jpg",
      },
      {
        name: "GhostMarket",
        url: "https://ghostmarket.io/",
        description: "​GhostMarket is a fully non-custodial NFT marketplace",
        icon: "./images/dapps/ghostmarket.jpeg",
      },
      {
        name: "Refinable",
        url: "https://refinable.com/",
        description: "Launch unique NFT projects and deepen customer engagement",
        icon: "./images/dapps/refinable.png",
      },
    ],
  },
  {
    name: "Fire Ecosystem",
    displayIos: false,
    icon: "fire-alt",
    color: "#c50000",
    dapps: [
      {
        name: "Firechain Network",
        url: "https://thefirechain.com",
        description: "Firechain mainnet",
        icon: "./images/dapps/firechainmainnet.png",
      },
      {
        name: "Rinia Testnet",
        url: "https://thefirechain.com",
        description: "Firechain Testnet",
        icon: "./images/dapps/firechaintestnet.png",
      },
      {
        name: "FireScan",
        url: "https://thefirescan.com",
        description: "Blockexplorer for the Firechain Network",
        icon: "./images/dapps/firescan.png",
      },
      {
        name: "Fire Bridge",
        url: "https://thefirebridge.com",
        description: "Token bridge for the Fire-Themed Ecosystem",
        icon: "./images/dapps/fireflame.png",
      },
      // {
      //   name: "FirePad",
      //   url: "https://thefirepad.com",
      //   description: "Launchpad of the Firechain Ecosystem",
      //   icon: "./images/dapps/fireflame.png",
      // },
      {
        name: "DONASWAP",
        url: "https://donaswap.com",
        description: "Donaswap rewards holders with redistrubuted tokens",
        icon: "./images/dapps/donaswap.png",
      },
    ],
  },
  {
    name: "Developer tools",
    displayIos: false,
    icon: "code",
    color: "#838c96",
    dapps: [
      {
        name: "Chainlist",
        url: "https://chainlist.org/",
        description: "A site for adding additional blockchains to your wallet",
        icon: "./images/dapps/chainlist.png",
      },
      {
        name: "Ethereum Name Service",
        url: "https://app.ens.domains/",
        description:
          "Have one name for all your cryptocurrency wallets, a decentralised website, & more",
        icon: "./images/dapps/ens.jpg",
      },
    ],
  },
  {
    name: "Suggest Something",
    displayIos: false,
    icon: "comment-alt",
    color: "#F8CD45",
    dapps: [

    ],
  },
  {
    name: "Markets",
    displayIos: false,
    icon: "chart-bar",
    color: "#FF849A",
    dapps: [
      {
        name: "CoinMarketCap",
        url: "https://coinmarketcap.com/",
        description: "cmc",
        icon: "./images/dapps/coinmarketcap.png",
      },
      {
        name: "Vulkania",
        url: "https://vulkania.io",
        description: "Vulkania Dashboard",
        icon: "./images/dapps/vulkania.png",
      },
      {
        name: "CoinBrain",
        url: "https://coinbrain.com",
        description: "coinbrain descr.",
        icon: "./images/dapps/coinbrain.png",
      },
      {
        name: "CoinDesk",
        url: "https://www.coindesk.com/",
        description: "Blockchain news",
        icon: "./images/dapps/coindesk.com.png",
      },
      {
        name: "CoinGecko",
        url: "https://www.coingecko.com/en",
        description: "Cryptocurrency Prices & Market Capitalization",
        icon: "./images/dapps/coingecko.com.png",
      },
    ],
  },
];

// hide any of the above on iOS when displayIos is false
const filtered = categories.filter(
  ({ displayIos = true }) => !(isIOS && !displayIos)
);

export default filtered;
